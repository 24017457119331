import './styles/app.scss';
import $ from 'jquery';

global.$ = global.jQuery = $;

$(document).ready(function () {
    if ($(window).width() < 768) {
        var $img = $('.adpanel__img--90');
        // $img.hide();
        // $('<div>', {class: 'toggle-img'}).text('Positionen anzeigen').click(function () {
        //     $img.toggle();
        // }).insertBefore($img);
        $img.parent().addClass('m-t-3').appendTo($('.adpanel'));
    }

    /*
     * Pie Charts
     */
    var $pieChart = $('.pie-chart'),
        piesBuilt = false;

    $.fn.isInViewport = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $(window).on('load resize scroll', function () {
        if ($pieChart.length && !piesBuilt && $pieChart.isInViewport()) {
            $pieChart.each(function () {
                createPie($(this));
                piesBuilt = true;
            });
        }
    });

    function sliceSize(dataNum, dataTotal) {
        return (dataNum / dataTotal) * 360;
    }

    function addSlice(sliceSize, pieElement, offset, sliceID, color) {
        pieElement.append("<div class='slice " + sliceID + "'><span></span></div>");
        offset = offset - 1;
        var sizeRotation = -179 + sliceSize;

        pieElement.find(" ." + sliceID).css({
            "transform": "rotate(" + offset + "deg) translate3d(0,0,0)"
        });

        pieElement.find(" ." + sliceID + " span").css({
            "transform": "rotate(" + sizeRotation + "deg) translate3d(0,0,0)",
            "background-color": '#' + color
        });
    }

    function iterateSlices(sliceSize, pieElement, offset, dataCount, sliceCount, color) {
        var maxSize = 179,
            sliceID = "s" + dataCount + "-" + sliceCount;

        if (sliceSize <= maxSize) {
            addSlice(sliceSize, pieElement, offset, sliceID, color);
        } else {
            addSlice(maxSize, pieElement, offset, sliceID, color);
            iterateSlices(sliceSize - maxSize, pieElement, offset + maxSize, dataCount, sliceCount + 1, color);
        }
    }

    function createPie(elem) {
        var listData = [],
            listTotal = 0,
            offset = 0,
            i,
            pieElement = elem.find('.pie-chart__pie');
        var dataElement = elem.find('.pie-chart__legend');

        var color = [
            '507bb7',
            '839cc9',
            'a3bedb',
            'a8cde2',
            'c0d5db',
            'c6c8c3'
        ];

        dataElement.find('span').each(function () {
            listData.push(Number($(this).attr('data-value')));
        });

        for (i = 0; i < listData.length; i++) {
            listTotal += listData[i];
        }

        for (i = 0; i < listData.length; i++) {
            var size = sliceSize(listData[i], listTotal);
            iterateSlices(size, pieElement, offset, i, 0, color[i]);
            dataElement.find("li:nth-child(" + (i + 1) + ")").css("border-color", '#' + color[i]);
            offset += size;
        }
    }

    function shuffle(a) {
        var j, x, i;
        for (i = a.length; i; i--) {
            j = Math.floor(Math.random() * i);
            x = a[i - 1];
            a[i - 1] = a[j];
            a[j] = x;
        }

        return a;
    }
});
